<template>
  <div
    v-show="path.length > 0"
    class="overlay waypoint-list"
  >
    <div
      v-if="multipleEditMode"
      class="waypoint-title"
    >
      <div class="button-group">
        <button
          class="image-button select-all left"
          @click="selectAll"
        >
          {{ $t('map.title.drone.selectall') }}
        </button>
        <div class="middle">
          <button
            class="image-button delete"
            :disabled="multipleEditSelect == 0"
            @click="deleteMultiple"
          />
          <button
            class="image-button pencil"
            :disabled="multipleEditSelect == 0"
            @click="editMultiple"
          />
        </div>
        <button
          class="image-button cancel right"
          @click="exitMultipleEdit"
        />
      </div>
      <div class="auto-heading-group">
        <div class="static">
          {{ $t('map.title.drone.auto_heading') }}
        </div>
        <el-switch
          v-model="autoHead"
          @change="$emit('changeAutoHeading',true)"
        />
      </div>
    </div>
    <div
      v-else
      class="waypoint-title"
    >
      <div class="button-group">
        <div class="static">
          {{ $t('map.title.drone.total') }} {{ path.length }}
        </div>
        <button
          v-if="editMode"
          class="image-button edit"
          @click="multipleEditMode = true"
        />
      </div>
      <div class="auto-heading-group">
        <div class="static">
          {{ $t('map.title.drone.auto_heading') }}
        </div>
        <el-switch
          v-model="autoHead"
          @change="$emit('changeAutoHeading',true)"
        />
      </div>
    </div>
    <div class="waypoint-item-list">
      <div
        v-for="(item, index) in path"
        :key="index"
        :class="`waypoint-item ${(item.activate) ? ' activate' : ''}`"
        @click="click(index)"
      >
        <template v-if="multipleEditMode">
          <div class="waypoint-selection">
            <button
              :class="{
                'image-button': true,
                'select': true,
                'activate': multipleEditSelect.indexOf(index) >= 0
              }"
            />
          </div>
        </template>
        <div class="waypoint-index">
          <span>{{ index }}</span>
        </div>
        <div class="waypoint-command">
          <img :src="WaypointNormalStyle[item.command].menu.icon">
        </div>
        <div class="waypoint-info">
          <span>{{ getTransAltitude(item.z) + disUnitHint }}<br>{{ getTransSpeed(item.speed) + speedUnitHint }}</span>
        </div>
      </div>
    </div>
    <waypoint-menu
      ref="waypointMenu"
      :path="multipleSummaryWaypoint"
      @save="multipleSave"
    />
  </div>
</template>

<script>
import { CopyObject } from '@/utils/common.js';
import { DisMeasure, SpeedMeasure } from '@/utils/measure';
import { WaypointNormalStyle } from '@/components/GoogleMaps/MarkerStyle.js';
import WaypointMenu from '@/components/Maps/WaypointMenu.vue';

export default {
  name: 'WaypointList',
  components: {
    WaypointMenu,
  },
  props: {
    path: {
      required: true,
      type: Array,
      twoWay: true,
    },
    editMode: {
      required: false,
      type: Boolean,
      default: true,
    },
    // autoHead: {
    //   required: true,
    //   type: Boolean,
    //   default: true,
    // },
  },
  data: () => ({
    WaypointNormalStyle,
    multipleEditMode: false,
    multipleEditSelect: [],
    multipleSummaryWaypoint: [],
    activatePath: null,
    autoHead: true,
  }),
  computed: {
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
    disUnitHint() {
      return DisMeasure.unit();
    },
  },
  methods: {
    click(index) {
      if (this.multipleEditMode) {
        const position = this.multipleEditSelect.indexOf(index);
        if (position == -1) {
          this.multipleEditSelect.push(index);
          return;
        }

        this.multipleEditSelect.splice(position, 1);
      } else {
        if (!this.editMode) {
          return;
        }
        if (this.activatePath != null) {
          return;
        }
        this.$emit('waypoint-click', index);
      }
    },
    multipleSave(index, data) {
      if (data.command === 'waypoint type') {
        delete data.command;
      }
      const path = CopyObject(this.path);
      for (const key in data) {
        const value = data[key];
        if (value === undefined) {
          continue;
        }
        for (const pathIndex of this.multipleEditSelect) {
          if (key === 'command' && pathIndex === 0) {
            continue;
          }
          path[pathIndex][key] = value;
        }
      }
      this.exitMultipleEdit();
      this.$emit('save', path);
    },
    editMultiple() {
      const firstSelected = this.path[this.multipleEditSelect[0]];

      const multipleSummaryData = CopyObject(firstSelected);
      delete multipleSummaryData.sequence;
      delete multipleSummaryData.x;
      delete multipleSummaryData.y;

      for (const pathIndex of this.multipleEditSelect) {
        const path = this.path[pathIndex];

        for (const key in path) {
          if (multipleSummaryData[key] == null) {
            continue;
          }

          if (multipleSummaryData[key] !== path[key]) {
            multipleSummaryData[key] = undefined;
          }
        }
      }

      multipleSummaryData.command = 'waypoint type';
      multipleSummaryData.count = this.multipleEditSelect.length;
      this.multipleSummaryWaypoint = [multipleSummaryData];
      const _this = this;
      this.$nextTick(() => {
        _this.$refs.waypointMenu.open(0);
      });
    },
    deleteMultiple() {
      const path = CopyObject(this.path);
      this.multipleEditSelect = this.multipleEditSelect.sort((a, b) => b - a);

      for (const index of this.multipleEditSelect) {
        if (path[index].command == 'takeoff') {
          continue;
        }
        path.splice(index, 1);
      }
      this.exitMultipleEdit();
      this.$emit('save', path);
    },
    activate(index) {
      this.$set(this.path[index], 'activate', true);
    },
    deActivate(index) {
      this.$set(this.path[index], 'activate', false);
    },
    exitMultipleEdit() {
      this.multipleEditSelect = [];
      this.multipleEditMode = false;
      this.$refs.waypointMenu.show = false;
    },
    selectAll() {
      const selectedLength = this.multipleEditSelect.length;
      this.multipleEditSelect = [];

      if (selectedLength == this.path.length) {
        return;
      }

      for (let i = 0; i < this.path.length; i += 1) {
        this.multipleEditSelect.push(i);
      }
    },
    getTransSpeed(value, digit = 1) {
      return SpeedMeasure.display(value, digit, 0, 'round');
    },
    getTransAltitude(value, digit = 0) {
      return DisMeasure.display(value, digit, 0);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables.scss';

$list_top_margin: 10px;
$lsit_title_height: 38px;

.waypoint-list {
  top: $list_top_margin;
  font-size: 14px;
  left: auto;
  right: 10px;
  width: 186px;
  line-height: 20px;
  color: white;

  .waypoint-title {
    height: 62px;
    // background-color: black;
    // padding: 9px 8px 9px 8px;
    margin-bottom: 2px;
    border-bottom: 1px;
    border-color: #B8B7B9;
    border-radius: 3px 3px 0 0;

    .button-group {
      display: flex;
      flex-direction: row;
      height: 26px;
      align-items: center;
      padding: 0px 8px 0px 8px;
      /* padding: 9px 0px 9px 6px; */
      background-color: black;

      .static, .middle {
        flex-grow: 1;
      }

      .middle {
        margin: 0 21px;
        display: flex;
        flex-direction: row;

        * {
          flex-grow: 1;
        }
      }
    }

    .auto-heading-group {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 36px;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0px 8px 0px 8px;

      .static, .middle {
        flex-grow: 1;
      }

      .middle {
        margin: 0 21px;
        display: flex;
        flex-direction: row;

        * {
          flex-grow: 1;
        }
      }
    }

    button {
      padding-left: 7px;
      padding-right: 7px;
    }

    // all button using flex get full height of container (button-group)
    button.select-all {
      color: white;
      padding: 0;
      height: 16px;
    }

    button.edit {
      width: 16px;
      height: 16px;
      background-image: url('/asserts/icon/missions-wpt-edit-icon-normal.svg');
    }

    button.delete {
      width: 14px;
      height: 16px;
      background-image: url('/asserts/icon/waypointlist-delete-icon-normal.svg');
    }

    button.delete:disabled {
      background-image: url('/asserts/icon/waypointlist-delete-icon-disable.svg');
    }

    button.pencil {
      width: 14px;
      height: 16px;
      background-image: url('/asserts/icon/waypointlist-edit-icon-normal.svg');
      margin-left: 15px;
    }

    button.pencil:disabled {
      background-image: url('/asserts/icon/waypointlist-edit-icon-disable.svg');
    }

    button.cancel {
      width: 12px;
      height: 16px;
      background-image: url('/asserts/icon/waypointlist-cancel-icon-normal.svg');
    }

  }

  .waypoint-item-list {
    border-radius: 0 0 3px 3px;
  }

  .waypoint-item-list {
    // padding to save => 5px
    // zoom button size => 60px
    // zoom button padding botton = 140px
    max-height: calc(100vh - #{$header_height} - #{$list_top_margin} - #{$lsit_title_height} - 5px - 60px - 140px);
    overflow-x: hidden;
    overflow-y: visible;

    .waypoint-item.activate {
      border: 0;
      border-left: 4px;
      border-style: solid;
      border-color: #35bbd1;

      .waypoint-index {
        padding: 0 8px 0 4px;
      }
    }

    .waypoint-item {
      background-color: rgba(0, 0, 0, 0.6);
      height: 62px;
      margin-bottom: 3px;
      font-size: 18px;

      .waypoint-index, .waypoint-command, .waypoint-info, .waypoint-selection {
        img, span {
          display: block;
          margin: auto;
        }

        height: 62px;
        float: left;
        display: flex;
      }

      .waypoint-index {
        padding: 0 8px;
      }

      .waypoint-command {
        width: 23px;
      }

      .waypoint-info {
        padding: 0 12px;
      }

      .waypoint-selection {
        button.select {
          width: 26px;
          margin-left: 9px;
          background-image: url('/asserts/icon/waypointlist-checkbox-unchecked.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        button.select.activate {
          background-image: url('/asserts/icon/waypointlist-checkbox-checked.svg');
        }

        button.select.drone {
          background-image: url('/asserts/icon/waypointlist-drone-icon.svg');
        }
      }
    }

    .waypoint-item:last-child {
      margin-bottom: 0;
    }

  }
}
</style>
