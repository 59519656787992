import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/groups`;

export default {
  getAll(group, filter) {
    return axios
      .get(`${apiUrl}/${group}/missions`, { params: filter })
      .then((response) => {
        return response.data;
      });
  },
  get(group, id) {
    return axios.get(`${apiUrl}/${group}/missions/${id}`).then((response) => {
      return response.data;
    });
  },
  create(group, data) {
    return axios.post(`${apiUrl}/${group}/missions`, data).then((response) => {
      return response.data;
    });
  },
  update(group, id, data) {
    return axios.put(`${apiUrl}/${group}/missions/${id}`, data).then((response) => {
      return response.data;
    });
  },
  delete(group, id) {
    return axios.delete(`${apiUrl}/${group}/missions/${id}`).then((response) => {
      return response.data;
    });
  },
};
