<template>
  <div
    v-show="show"
    class="overlay path-menu"
  >
    <!--  多航點編輯 title  -->
    <div
      v-if="isMultipleEdit"
      class="title--multi"
    >
      {{ waypoint.count }} {{ $t("map.waypointMenu.selected") }}
    </div>

    <!--   select 區塊   -->
    <div class="title">
      <span
        v-if="!isMultipleEdit"
        class="index"
      >{{ parseInt(pathIndex) }}</span>

      <template v-if="isMultipleEdit">
        <el-select
          v-model="waypoint.command"
          placeholder=""
          popper-class="in-map waypoint-dropdown"
        >
          <el-option
            v-for="(type, index) in multipleEditSelection"
            :key="index"
            :label="$t(`map.waypointMenu.${type.displayName}`)"
            :value="index"
          >
            <label class="waypoint-dropdown-label">
              <img :src="type.menu.icon">
            </label>

            {{ $t(`map.waypointMenu.${type.displayName}`) }}
          </el-option>
        </el-select>
      </template>
      <template v-if="waypoint.command === 'takeoff' && !isMultipleEdit">
        <el-select
          v-model="waypoint.command"
          placeholder=""
          popper-class="in-map waypoint-dropdown"
        >
          <el-option
            :label="$t(`map.waypointMenu.${WaypointNormalStyle.takeoff.displayName}`)"
            value="takeoff"
          >
            <img :src="WaypointNormalStyle.takeoff.menu.icon">

            {{ $t(`map.waypointMenu.${WaypointNormalStyle.takeoff.displayName}`) }}
          </el-option>
        </el-select>
      </template>
      <!-- <template v-else-if="waypoint.command == 'land'">
        <el-select v-model="waypoint.command" placeholder="" popper-class="in-map waypoint-dropdown">
          <el-option
            :label="$t(`map.waypointMenu.${WaypointNormalStyle.land.displayName}`) "
            value="land">
            <img :src="WaypointNormalStyle.land.menu.icon">

            {{ $t(`map.waypointMenu.${WaypointNormalStyle.land.displayName}`) }}
          </el-option>
        </el-select>
      </template> -->
      <template v-if="waypoint.command !== 'takeoff' && !isMultipleEdit">
        <el-select
          v-model="waypoint.command"
          placeholder=""
          popper-class="in-map waypoint-dropdown"
        >
          <el-option
            v-for="(type, index) in missionModes"
            :key="index"
            :label="$t(`map.waypointMenu.${type.displayName}`)"
            :value="index"
          >
            <label class="waypoint-dropdown-label">
              <img :src="type.menu.icon">
            </label>

            {{ $t(`map.waypointMenu.${type.displayName}`) }}
          </el-option>
        </el-select>
      </template>
    </div>

    <div class="body">
      <el-form>
        <template v-if="waypoint.command == 'takeoff' || waypoint.command == 'land'">
          <el-form-item>
            <label class="el-form-item__label">{{ $t("map.waypointMenu.selectNest") }}</label>
            <el-select
              v-model="selectedNest"
              size="mini"
              class="nest value"
            >
              <el-option
                v-for="(nest, index) in nests"
                v-if="!nestOccupy(nest)"
                :key="`nest-${index}`"
                :label="nest.name"
                :value="nest.id"
              />
            </el-select>
          </el-form-item>
        </template>
        <el-form-item :label="$t('map.waypointMenu.altitude')">
          <el-input-number
            v-model="displayZ"
            size="mini"
            :min="minAltitude"
            :max="maxAltitude"
          />
        </el-form-item>
        <el-form-item :label="$t('map.waypointMenu.delay')">
          <el-input-number
            v-model="waypoint.delay"
            size="mini"
            :min="0"
            :max="30"
          />
        </el-form-item>
        <el-form-item :label="$t('map.waypointMenu.speed')">
          <el-input-number
            v-model="displaySpeed"
            size="mini"
            :min="minSpeed"
            :max="maxSpeed"
          />
        </el-form-item>
        <el-form-item :label="$t('map.waypointMenu.tilt')">
          <el-input-number
            v-model="waypoint.tilt"
            size="mini"
            :min="-90"
            :max="15"
          />
        </el-form-item>
        <el-form-item
          v-if="!isMultipleEdit"
          :label="$t('map.waypointMenu.heading')"
        >
          <el-input-number
            v-model="waypoint.heading"
            size="mini"
            :min="0"
            :max="360"
            @change="$emit('changeAutoHeading')"
          />
        </el-form-item>
        <el-form-item
          v-if="waypoint.command === 'do_intervalcam_control'"
          :label="$t('map.waypointMenu.photo_duration')"
        >
          <el-input-number
            v-model="waypoint.photo_duration"
            size="mini"
            :min="minPhotoDuration"
            :max="maxPhotoDuration"
          />
        </el-form-item>
        <el-form-item :label="$t('map.waypointMenu.notify_ai')">
          <el-select
            v-model="waypoint.notify_ai"
            size="mini"
            class="mini-select"
            placeholder=""
          >
            <el-option
              v-for="item in notifyAIMode"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <template v-if="!isMultipleEdit && waypoint.command != 'takeoff' && waypoint.command != 'land'">
          <el-form-item>
            <label class="el-form-item__label">{{ $t("map.latitude") }}</label>
            <input
              v-model="waypoint.x"
              type="number"
              class="latLng value"
              step="0.000000000000001"
            >
          </el-form-item>
          <el-form-item>
            <label class="el-form-item__label">{{ $t("map.longitude") }}</label>
            <input
              v-model="waypoint.y"
              type="number"
              class="latLng value"
              step="0.000000000000001"
            >
          </el-form-item>
        </template>

        <el-form-item style="text-align: center">
          <el-button
            size="mini"
            class=""
            type="info"
            @click="close"
          >
            {{ $t("button.cancel") }}
          </el-button>
          <el-button
            :disabled="!isAdmin"
            size="mini"
            class=""
            type="primary"
            @click="save"
          >
            {{ $t("button.done") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WaypointNormalStyle } from '../GoogleMaps/MarkerStyle';
import { CopyObject } from '../../utils/common';
import { DisMeasure, SpeedMeasure } from '@/utils/measure';
import NestApi from '../../services/api/domain/group/nest';

export default {
  name: 'WaypointMenu',
  props: {
    path: {
      required: true,
      type: Array,
    },
    allowCounting: {
      required: false,
      type: Boolean,
      default: false,
    },
    obstacleZone: {
      type: [Object, null],
    },
    altitudeLimitZone: {
      type: [Object, null],
    },
  },
  data: () => ({
    WaypointNormalStyle,
    show: false,
    waypoint: {},
    pathIndex: 0,
    selectedNest: null,
    nests: [],
    selectedNests: null,
    minPhotoDuration: 1,
    maxPhotoDuration: 255,
    notifyAIMode: [
      {
        value: 0,
        label: 'Default',
      },
      {
        value: 1,
        label: 'Start',
      },
      {
        value: 2,
        label: 'Stop',
      },
    ],
    displayZ: 0,
    displaySpeed: 0,
  }),
  computed: {
    isMultipleEdit() {
      return this.path.length && this.path[0].command === 'waypoint type';
    },
    multipleEditSelection() {
      const enabledType = ['Waypoint', 'Camera', 'Intervalcam'];
      return Object.keys(this.enabledSelection)
        .filter((key) => enabledType.includes(this.enabledSelection[key].displayName))
        .reduce((obj, key) => {
          obj[key] = this.WaypointNormalStyle[key];
          return obj;
        }, {});
    },
    enabledSelection() {
      return Object.keys(this.WaypointNormalStyle)
        .filter((key) => !this.WaypointNormalStyle[key].disabled && this.nestOnlyOne(this.WaypointNormalStyle[key]))
        .filter((key) => this.allowCounting || this.WaypointNormalStyle[key].displayName !== 'Counting')
        .reduce((obj, key) => {
          obj[key] = this.WaypointNormalStyle[key];
          return obj;
        }, {});
    },
    missionModes() {
      return Object.keys(this.WaypointNormalStyle)
        .filter((key) => !this.WaypointNormalStyle[key].disabled && this.nestOnlyOne(this.WaypointNormalStyle[key]))
        .filter((key) => this.showCounting || this.WaypointNormalStyle[key].displayName !== 'Counting')
        .reduce((obj, key) => {
          obj[key] = this.WaypointNormalStyle[key];
          return obj;
        }, {});
    },
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      group: 'user/group',
    }),
    maxAltitude() {
      let max = 120;
      if (this.group && this.group.max_altitude != null) {
        max = this.group.max_altitude;
      }
      if (this.altitudeLimitZone && this.altitudeLimitZone.max_altitude - 1 < max) {
        max = this.altitudeLimitZone.max_altitude - 1;
      }
      return this.getTransAltitude(max, 0, 'round');
    },
    minAltitude() {
      let min = 15;
      if (this.group && this.group.min_altitude != null) {
        min = this.group.min_altitude;
      }

      if (this.obstacleZone && this.obstacleZone.min_altitude + 1 > min) {
        min = this.obstacleZone.min_altitude + 1;
      }
      return this.getTransAltitude(min, 0, 'round');
    },
    showCounting() {
      const coungingIndex = this.path.findIndex((element) => element.command === 'counting');
      if (coungingIndex === -1) {
        return this.allowCounting;
      }
      return this.allowCounting && coungingIndex === this.pathIndex;
    },
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
    disUnitHint() {
      return DisMeasure.unit();
    },
    minSpeed() {
      return SpeedMeasure.display(1, 0, 0, 'round');
    },
    maxSpeed() {
      return SpeedMeasure.display(20, 0, 0, 'round');
    },
  },
  watch: {
    async selectedNest(now, old) {
      const nest = this.nests.find((nest) => nest.id === now);
      if (nest) {
        this.waypoint.x = nest.latitude;
        this.waypoint.y = nest.longitude;
      }
    },
    waypoint(now, old) {
      if (now.command == 'takeoff' || now.command == 'land') {
        const nest = this.nests.find((nest) => nest.latitude == now.x && nest.longitude == now.y);
        if (nest) {
          this.selectedNest = nest.id;
        } else {
          this.selectedNest = null;
        }
      }
    },
  },
  mounted() {
    NestApi.getAll(this.group.id).then((res) => {
      this.nests = res.data.nests;
    });
  },
  methods: {
    nestOccupy(nest) {
      if (this.selectedNests) {
        let result = false;
        this.selectedNests.forEach((element) => {
          if (element.x == nest.latitude && element.y == nest.longitude && nest.id !== this.selectedNest) {
            result = true;
          }
        });
        return result;
      }
      return false;
    },
    nestOnlyOne(type) {
      if (type.displayName == 'Landing' && this.nests.length == 1) {
        return false;
      }
      return true;
    },
    open(index, selectedNests) {
      this.show = true;
      this.pathIndex = index;
      this.selectedNest = null;
      this.selectedNests = selectedNests;
      this.waypoint = CopyObject(this.path[index]);
      this.displayZ = this.getTransAltitude(this.waypoint.z);
      this.displaySpeed = this.getTransSpeed(this.waypoint.speed);
    },
    close() {
      this.show = false;
      this.$emit('close', this.pathIndex);
    },
    save() {
      const data = CopyObject(this.waypoint);
      data.z = DisMeasure.real(this.displayZ, 2);
      data.speed = SpeedMeasure.real(this.displaySpeed, 2);
      // native input require parseFloat
      if (typeof data.x === 'string') {
        data.x = parseFloat(data.x);
      }
      if (typeof data.y === 'string') {
        data.y = parseFloat(data.y);
      }
      delete data.activate;
      if ((data.command == 'takeoff' || data.command == 'land') && !this.selectedNest) {
        this.$showFail('errorMsg.nestNotBind');
        return;
      }

      this.$emit('save', this.pathIndex, data);
    },
    getTransSpeed(value, digit = 1) {
      return SpeedMeasure.display(value, digit, 0, 'round');
    },
    getTransAltitude(value, digit = 0, mode = 'round') {
      return DisMeasure.display(value, digit, 0, mode);
    },
  },
};
</script>

<style lang="scss">
.path-menu {
  top: 50px;
  left: auto;
  right: 202px;
  width: 200px;
  // width: 400px;
  min-height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;

  .title.single {
    float: none;

    .index {
      width: 100%;
      margin: 0;
    }
  }

  .title {
    // background-color: black;
    height: 32px;
    margin: 4px;
    float: left;
    display: flex;

    img,
    span {
      display: block;
      margin: auto;
    }

    .index {
      padding: 6px;
      margin-right: 2px;
      background-color: black;
      height: inherit;
      color: white;
    }

    .el-select {
      height: inherit;

      .el-input {
        height: inherit;

        .el-input__inner {
          line-height: inherit;
          color: white;
          background-color: black;
          height: inherit;
          border: 0;
          border-radius: 0;
        }
      }
    }

    .title__content.take-off {
      line-height: 32px;
      background-color: black;
      margin-right: 2px;
    }

    &--multi {
      color: #fffbaa;
      margin: 0.25rem 0.25rem 0 0.5rem;
    }
  }

  .body {
    clear: both;
    padding-top: 1px;

    .el-form {
      padding: 0px 8px;

      .el-form-item {
        margin-top: 10px;
        margin-bottom: 10px;

        label.el-form-item__label {
          color: white;
          width: 110px;
          text-align: left;
          line-height: 15px;
        }

        .latLng.value {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 4px;
          -moz-appearance: textfield;
          background-color: black;
          color: white;
          width: 110px;
          border: 1px;
          border-color: white;
          border-style: solid;
          padding: 0px 4px;
          line-height: 20px;
          overflow: hidden;
        }

        .nest.value {
          /* display: block; */
          /* margin-left: auto; */
          /* margin-right: auto; */
          margin-top: 4px;
          width: 190px;
          /* padding: 0px 4px; */
          line-height: 20px;
          overflow: hidden;

          .el-input--suffix .el-input__inner {
            /*font-size: 18px; */
            font-weight: initial;
            color: white;
            background-color: rgba(3, 3, 3, 0.15);
            border: 1;
            width: 184px;
          }
        }

        input.latLng.value::-webkit-outer-spin-button,
        input.latLng.value::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .el-input-number--mini {
          width: 100px;
        }

        .el-form-item__label {
          font-size: 12px;
          opacity: 0.9;
        }

        .el-form-item__content {
          line-height: 30px;
          text-align: center;
        }

        .el-select-dropdown__item {
          img {
            width: 14px;
            display: inline-block;
            line-height: 34px;
            vertical-align: middle;
          }
        }
        .mini-select {
          border: 0px;
          width: 100px;
          font-size: 12px;
        }
      }
    }
  }
}

.waypoint-dropdown {
  .el-select-dropdown__item {
    display: flex;
    flex-direction: row;

    .waypoint-dropdown-label {
      width: 24px;
      position: relative;
      margin-right: 5px;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

// .el-select-dropdown.el-popper {

// }

// .el-select-dropdown__item {
//   // background-color: #dbdbdb;
//   color: black;
// }
// .el-select-dropdown__item.hover {
//   background-color: #C2C2C2;
//   color: black;
// }
// .el-scrollbar__view.el-select-dropdown__list {
//     padding: 0;
//     background-color: #dbdbdb;
// }

// .popper__arrow {
//   display: none !important;
// }
</style>
