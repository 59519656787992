<template>
  <div>
    <gmap-polyline
      :path="path.plan"
      :options="mainPolylineOptions"
    />
    <gmap-polyline
      :path="path.done"
      :options="donePolylineOptions"
    />
    <gmap-polyline
      :path="path.returnHome"
      :options="returnHomePolylineOptions"
    />
    <gmap-polyline
      :path="history"
      :options="historyPolylineOptions"
    />

    <template v-for="(waypoint, wIndex) in path.fullPath">
      <gmap-marker
        :key="`pathWaypont${wIndex}`"
        :position="waypoint"
        :icon="waypoint.icon"
        :z-index="1000 + 1000 * index + wIndex"
        :label="waypoint.label"
        :draggable="editMode"
        @dragstart="waypointDragstart"
        @drag="(event) => waypointDrag(event, wIndex)"
        @dragend="(event) => waypointDragend(event, wIndex)"
        @click="$emit('marker-click', wIndex)"
      />
      <gmap-marker
        v-if="wIndex < path.fullPath.length - 1"
        :key="`pathMiddleWaypont${wIndex}`"
        :position="middlePath[wIndex]"
        :icon="WaypointStyle.middleWaypoint"
        @click="(event) => $emit('middle-marker-click', wIndex,event)"
      />
    </template>
    <gmap-marker
      v-for="(distance, idx) in eachDisDisplay"
      :key="`pathMiddleWaypontlabel${idx}`"
      :position="middlePath[idx]"
      :label="{
        text: `${distance}`,
        className: 'waypoint__label',
      }"
      icon="''"
    />
  </div>
</template>

<script>
import {
  WaypointNormalStyle, WaypointStyle, WaypointSelectedStyle, WaypointFinishedStyle, WaypointNextStyle,
} from './MarkerStyle';
import PolylineStyle from './PolylineStyle';
import { CopyObject } from '@/utils/common';
import { DisMeasure } from '@/utils/measure';
import { GeographicLonger } from '@/utils/map';

export default {
  name: 'FlyRoute',
  components: {
  },
  props: {
    task: {
      type: Array,
      twoWay: true,
      noBind: true,
    },
    eachDis: {
      required: false,
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: true,
      noBind: true,
    },
    index: {
      type: Number,
      default: 0,
      noBind: true,
    },
    history: {
      type: Array,
      default() { return []; },
    },
    useNormalIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ...PolylineStyle,
      WaypointNormalStyle,
      WaypointStyle,
    };
  },
  computed: {
    path() {
      const iconSet = (this.useNormalIcon) ? WaypointNormalStyle : WaypointStyle;
      const source = this.task;
      const fullPath = source.map((waypoint, index) => {
        let icon = iconSet[waypoint.command];
        if (waypoint.activate) {
          icon = WaypointSelectedStyle[waypoint.command];
        } else if (waypoint.next) {
          icon = WaypointNextStyle[waypoint.command];
        } else if (waypoint.finished) {
          icon = WaypointFinishedStyle[waypoint.command];
        }
        const label = this.getLabel(waypoint, index);

        const value = {
          icon,
          label,
          ...waypoint,
          lat: waypoint.x,
          lng: waypoint.y,
        };
        delete value.x;
        delete value.y;
        return value;
      });
      let position = fullPath.findIndex((waypoint) => !waypoint.finished);
      const lastWaypoint = fullPath[parseInt(fullPath.length) - 1];
      if (position > 0) {
        position -= 1;
      }
      return {
        fullPath,
        position,
        plan: fullPath.slice(position),
        done: (position === -1) ? fullPath : fullPath.slice(0, position + 1),
        returnHome: (lastWaypoint && lastWaypoint.command === 'rtl') ? [lastWaypoint, fullPath[0]] : [],
      };
    },
    middlePath() {
      const middlePath = [];
      const totalPath = CopyObject(this.path.fullPath);
      if (totalPath[totalPath.length - 1].command === 'rtl') {
        totalPath.push(this.path.fullPath[0]);
      }
      if (!this.editMode) {
        return middlePath;
      }
      for (const index in totalPath) {
        const path = totalPath[index];
        const nextPath = totalPath[parseInt(index) + 1];

        if (nextPath == null) {
          return middlePath;
        }

        middlePath.push({
          lat: (path.lat + nextPath.lat) / 2,
          lng: (path.lng + nextPath.lng) / 2,
        });
      }
      return middlePath;
    },
    disUnitHint() {
      return DisMeasure.unit();
    },
    eachDisDisplay() {
      return this.eachDis.map((dis) => this.getTransDis(dis, 1) + this.disUnitHint);
    },
  },
  methods: {
    getLabel(marker, index) {
      const iconSet = (this.useNormalIcon) ? WaypointNormalStyle : WaypointStyle;
      const defaultLabel = {
        color: 'white',
        text: index.toString(),
        size: (this.useNormalIcon) ? '14px' : '12px',
      };
      let markerLabel = (iconSet[marker.command].label !== undefined) ? iconSet[marker.command].label : defaultLabel;
      if (markerLabel === null) {
        markerLabel = '';
      }
      return markerLabel;
    },
    waypointDragstart() {
      this.$emit('marker-dragstart');
      // this.previewTask = CopyObject(this.task);
    },
    waypointDragend(event, index) {
      const position = GeographicLonger(event.latLng);
      this.task[index].x = event.latLng.lat();
      this.task[index].y = event.latLng.lng();
      this.$emit('marker-dragend', {
        position,
        index,
        new: CopyObject(this.task[index]),
      });
    },
    waypointDrag(event, index) {
      this.task[index].x = event.latLng.lat();
      this.task[index].y = event.latLng.lng();
    },
    getTransDis(value, digit = 0) {
      return DisMeasure.display(value, digit, 0, 'round');
    },
  },
};
</script>
<style lang="scss">
div.waypoint__label{
  background: rgba(0, 0, 0, 0.7);
  font-size: .75rem !important;
  color: #FFF !important;
  padding: .1rem .25rem;
  transform: translateY(2.25rem);
}
</style>
